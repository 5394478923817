<template>
<div class="b-textarea w-100">
    <div class="form-group">
        <label>{{title}}</label>
        <textarea  @input="handleInput"  class="form-control" :rows="rows">{{ value }}</textarea>
    </div>
</div>
</template>
<script>
export default {
    props: {
         title: {
            type: String,
            default: ''
        },
        hint: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: ''
        },
        value: {
            type: [String, Number, Boolean],
            default: null
        },
        rows: {
            type: [String, Number],
            default: 5
        }
    },
    
    methods: {
        handleInput(e) {
            this.$emit('input', e.target.value);
        }
    }
}
</script>