<template>
    <div class="b-checkbox">
        <div class=" custom-control custom-checkbox">
            <input 
                type="checkbox" 
          
                class="custom-control-input"
                @click="toggle"
                :checked="value"
                @change="$emit('change', $event)"
                @input="handleInput">
            <label class="custom-control-label" for="defaultCheck1" @click="toggle">
                {{label}}
                <slot name="label"></slot>
            </label>
        </div>
    </div>
</template>
<script>
export default {
    props: {

        value: {
            type: [Boolean, String],
            default: false
        },
        label: {
            type: String,
            default: ''
        }
    },
    computed: {
        // is_checked(val){
        //     return this.value == val;
        // },
    },
    methods: {
  
        handleInput(e){
       
            this.$emit('input', e.target.checked);
        },
        toggle(){
            this.$emit('input', !this.value);
        }
    }
}
</script>