
import Button from './Button.vue';
import Icon from './Icon.vue';
import Input from './Input.vue';
import Checkbox from './Checkbox.vue';
import Modal from './Modal.vue';
import TextArea from './TextArea.vue';
import Pagination from './Pagination.vue';



export const BButton = Button;
export const BIcon = Icon;
export const BCheckbox = Checkbox;
export const BInput = Input;
export const BModal = Modal;
export const BTextArea = TextArea;
export const BPagination = Pagination;

