import axios from 'axios';

const baseURL = process.env.VUE_APP_CUSTOMER_SERVICE;

const client = axios.create({
    baseURL: baseURL,
});

export default {
    user: {
        register(body){
            return client.post('/user', body)
        }
    }
}
