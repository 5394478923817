<template>
  <div class="b-modal">
    <transition name="fade">
      <div class="modal" v-if="value">
        <div
          class="modal-dialog"
          :class="dialog_classes"
          v-click-outside="clickOutside"
          :style="dialog_styles"
        >
          <div class="modal-content">
            <div class="modal-header" v-if="!hide_header">
              <h5
                class="modal-title w-100 font-weight-bold text-center"
                id="exampleModalLabel"
              >
                {{ title }}
              </h5>
              <div class="close">
                <b-button text icon @click="close">
                  <b-icon solid icon="times"></b-icon>
                </b-button>
              </div>
            </div>
            <div class="modal-body">
              <slot name="content"></slot>
            </div>
            <div class="modal-footer" v-if="!hide_footer">
              <slot name="footer"></slot>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <transition name="fade">
      <div class="modal-backdrop" v-show="value"></div>
    </transition>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    centered: {
      type: Boolean,
      default: false,
    },
    scrollable: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    hide_footer: {
      type: Boolean,
      default: false,
    },
    hide_header: {
      type: Boolean,
      default: false,
    },
    max_width: {
      type: [Number, String],
      default: 500,
    },
  },

  computed: {
    dialog_classes() {
      return {
        "modal-dialog-centered": this.centered,
        "modal-dialog-scrollable": this.scrollable,
      };
    },
    backdrop_classes() {
      return {
        show: !!this.value,
      };
    },
    dialog_styles() {
      return {
        maxWidth: this.max_width + "px",
      };
    },
  },
  methods: {
    close() {
      this.$emit("input", false);
      this.$emit("close");
    },
    clickOutside: function (event) {
      if (this.value == true) {
        this.$emit("input", false);
        this.$emit("close");
      }
    },
  },
};
</script>