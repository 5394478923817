import _ from 'lodash';
import api from '../../api/zendesk';
import { getField, updateField } from 'vuex-map-fields';
import router from '../../router';
import moment from 'moment';
import instance from './forms/support';


let initialState = {
    loading: false
}

const state = () => ({
    ...initialState
});

const getters = {
    getField,


    
}

const actions = {
    fetch(context){
        let promise;
        
        promise = api.ticket.fetch();


        context.commit('setLoading', true);

        return promise.then(response=>{
            context.commit('setLoading', false);
        }).catch(e=>{
            context.commit('setLoading', false);
            console.log(e);
        })
    },

 
   
}

const mutations = {
    updateField,
    setLoading(state, status){
        state.loading = status;
    },


}
const modules = {
    instance: instance,
};
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
    modules
}