<template>
  <div class="navbar">
    <div class="navbar__container container">
      <router-link class="navbar__logo" :to="{name:'home'}">
        <img src="../../assets/logo.svg" alt="Logo image" width="50" height="59">
      </router-link>
      <div class="navbar__list ">
        <ul>
          <li v-for="(item, key) in items" :key="key">
            <router-link
                :to="resolveLink(item)"
                v-scroll-to="resolveScrollPosition(item)">{{ item.title }}</router-link>
          </li>

        </ul>
      </div>
      <transition name="expand">
        <div class="navbar__mobilelist " v-show="is_mobile_open">
          <ul>
            <li v-for="(item, key) in items" :key="key">
              <router-link
                  :to="resolveLink(item)"
                  v-scroll-to="resolveScrollPosition(item)">{{ item.title }}</router-link>
            </li>
          </ul>
        </div>
      </transition>
      <div class="navbar__icon " :class="is_mobile_open ? '' : 'active' " @click="toggleMobileNav">
        <span></span><span></span><span></span><span></span>
      </div>
      <div class="navbar__action">
        <a class="btn btn-secondary btn-small btn-outlined" href="https://scheduler.zoom.us/tom-belote/shelfset" target="_blank">
          <span class="d-flex align-items-center justify-content-center h-100">Contact</span>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import {get} from "lodash";
export default {
  props: {
    items: {
      type: Array,
      default: () => []
    }
  },
  data(){
    return {
      is_mobile_open: false
    }
  },
  watch: {
    $route(){
      this.is_mobile_open = false;
    }
  },
  methods: {
    resolveLink(item) {
      return get(item, 'to', {path: '/'})
    },
    resolveScrollPosition(item) {
      return get(item, 'scrollto', '#app');
    },
    toggleMobileNav(){
      this.is_mobile_open = !this.is_mobile_open;
      this.toggleBodyClass();
    },
    toggleBodyClass(){
      if(this.is_mobile_open){
        document.body.classList.add('nav-open');
      } else {
        document.body.classList.remove('nav-open');
      }
    }
  }
}
</script>