import axios from 'axios';

const baseURL = process.env.VUE_APP_NOTIFICATIONS_SERVICE;

const client = axios.create({
    baseURL: baseURL,
});

client.interceptors.request.use(function (config) {
    const username =  process.env.VUE_APP_NOTIFICATIONS_BASIC_USER;
    const password =  process.env.VUE_APP_NOTIFICATIONS_BASIC_PASS;
    config.headers.Authorization = 'Basic ' + btoa(username + ':' + password);

    return config;
});

export default {
    send(params){
        return client.post('/notification', params)
    }

}
