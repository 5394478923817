<template>
    <component :is="component" @click.prevent="$emit('click')" class="btn" :class="class_obj" :disabled="disabled" :to="to" :href="href">
        <div class="d-flex align-items-center justify-content-center h-100">
            <span v-if="loading" class="spinner-border spinner-border-sm mr-1"></span>
            <slot></slot>
        </div>
    </component>
</template>
<script>
export default {
  name: 'Home',
  props: {
    color: {
        type: String,
        default: 'primary'
    },
    disabled: {
        type: Boolean,
        default: false
    },
    loading: {
        type: Boolean,
        default: false
    },
    icon: {
        type: Boolean,
        default: false
    },
    text: {
        type: Boolean,
        default: false
    },
    small: {
        type: Boolean,
        default: false
    },
    large: {
        type: Boolean,
        default: false
    },
    outlined: {
        type: Boolean,
        default: false
    },
    link:{
        type: Boolean,
        default: false
    },
    to: {
        type: [String, Object],
        default: null
    },
    href: {
        type: String,
        default: null
    },
    small: {
        type: Boolean,
        default: false
    },
    shadow: {
        type: Boolean,
        default: false
    },
    block: {
        type: Boolean,
        default: false
    },
    x_small: {
        type: Boolean,
        default: false
    },
  },
  computed: {
      class_obj(){
          return {
              'btn-primary': this.color == 'primary',
              'btn-secondary': this.color == 'secondary',
              'btn-success': this.color == 'success',
              'btn-danger': this.color == 'danger',
              'btn-warning': this.color == 'warning',
              'btn-info': this.color == 'info',
              'btn-light': this.color == 'light',
              'btn-dark': this.color == 'dark',
              'btn-link': this.color == 'link',
              'btn-icon': !!this.icon,
              'btn-text': !!this.text,
              'btn-small': !!this.small,
              'btn-large': !!this.large,
              'btn-outlined': !!this.outlined,
              'btn-link': !!this.link,
              'btn-small': !!this.small,
              'btn-shadow': !!this.shadow,
               'btn-block': !!this.block,
              'btn-xsmall': !!this.x_small
          }
      },
      component(){
          if(this.to){
              return 'router-link'
          }
          if(this.href){
              return 'a';
          }
          return 'button'
      }
  }
}
</script>