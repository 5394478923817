// {
//     "request": {
//       "requester": {"name": "Anonymous customer"},
//       "subject": "Help!",
//       "comment": {"body": "My printer is on fire!" }
//     }
//   }

import _ from 'lodash';
import api from '../../../api/zendesk'
import router from '../../../router';
import { getField, updateField } from 'vuex-map-fields';


let initialState = {

    loading: false,
   plan_instance: {
        request: {
            requester: {"name": null, email: null},
            subject: "Request A Plan",
            "comment": {"body": null }
        }
    },
    contact_instane: {
        request: {
            requester: {"name": null, email: null},
            subject: "Question",
            "comment": {"body": null }
        } 
    }

}

const state = () => ({
    ...initialState
});

const getters = {
    getField,
    
}

const actions = {
    createPlanRequest(context){
        let promise;
        let instance = {...context.state.plan_instance};

        promise = api.request.create(instance);
        // console.log(instance);
        return promise.then(response=>{
            context.commit('setLoading', false);

            return { type: 'success' }
        }).catch(e=>{
            context.commit('setLoading', false);
            console.log(e);
            return { type: 'error' }
        })
    },
    createContactRequest(context){
        let promise;
        let instance = {...context.state.contact_instane};

        promise = api.request.create(instance);
        // console.log(instance);
        return promise.then(response=>{
            context.commit('setLoading', false);

            return { type: 'success' }
        }).catch(e=>{
            context.commit('setLoading', false);
            console.log(e);
            return { type: 'error' }
        })
    }
 
};

const mutations = {
    updateField,
    setLoading(state, status){
        state.loading = status;
    },


}
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}