import api from '../../../api/user';
import _ from 'lodash';
import { getField, updateField } from 'vuex-map-fields';

const state = () => ({
    instance: {
        uuid: null,
        email: null,
        password: null,
        password_confirmation: null,
        role: 'admin',
        first_name: null,
        last_name: null,
        phone_num: null,
        company: {
            name: null
        },
        subscribe: true,
    },
    errors: [],
    loading: false,
})

const getters = {
    getField
}


const actions = {
    register({commit, state}) {
        commit('applyLoading', true);
        commit('applySetErrors', {errors: []});
        let instance = state.instance;
        api.user.register(instance)
            .then(response => {
                commit('applyUserRegistered', response.data)
            })
            .catch(error => {
                if (error.response) {
                    // console.log(error.response.data);
                    // console.log(error.response.status);
                    commit('applySetErrors', error.response.data)
                }
            })
            .finally(() => {
                commit('applyLoading', false);
            })
    }
}


const mutations = {
    updateField,
    applyLoading(state, flag) {
        state.loading = flag
    },
    applySetErrors(state, data) {
        state.errors = data.errors;
    },
    applyUserRegistered(state, data) {
        let tokens = JSON.stringify({a: data.access_token, r: data.refresh_token})
        window.location = `${process.env.VUE_APP_CABINET_URL}login?_token=${btoa(tokens)}`;
    }
}


export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}