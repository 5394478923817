<template>
    <footer class="footer">
        <div class="container">
            <div class="row">
                <div class="col col-md-2 col-12 mb-3 order-2 order-md-1">
                    <div class="footer__socials">
                        <a href="#">
                          <font-awesome-icon :icon="['fab', 'facebook-f']" />
                        </a>
                        <a href="#">
                          <font-awesome-icon :icon="['fab', 'twitter']" />
                        </a>
                        <a href="#">
                          <font-awesome-icon :icon="['fab', 'instagram']" />
                        </a>
                    </div>
                </div>
                <div class="col col-md-8 col-12 order-1 order-md-2">
                    <div class="footer__links">
                        <ul>
                            <li>
                                <a href="/#about" @click.prevent="navTo({name: 'home'})" v-scroll-to="'#about'">About</a>
                            </li>
                            <li>
                                <a href="/#contact" @click.prevent="navTo({name: 'home'})" v-scroll-to="'#contact'">Contact</a>
                            </li>
                            <li>
                                <a href="/#features" @click.prevent="navTo({name: 'home'})" v-scroll-to="'#features'">Features</a>
                            </li>
                            <li>
                                <router-link :to="{name: 'privacy'}" v-scroll-to="0">Privacy Policy</router-link>
                            </li>
                            <li>
                                <a href="https://app.termly.io/document/terms-of-use-for-saas/7db73074-cc0f-43e9-bebf-261f1eefe62f" target="_blank">Terms And Conditions</a>
                            </li>

                        </ul>
                    </div>
                </div>
                <div class="col col-md-2 col-12 order-3">
                    <div class="footer__contact text-right text-md-left">
                        <a href="mailto:info@shelfset.app">info@shelfset.app</a>
                        <a href="tel:+12012572569">+1-201-257-2569</a>
                    </div>
                </div>
                <div class="col col-12 pt-3 pb-2 order-4">
                    <small>&copy; Copyright ShelfSet LLC - {{current_year}}</small>
                </div>
            </div>


        </div>
    </footer>
</template>
<script>
export default {
    computed: {
        current_year(){
            return this.$moment().format('YYYY')
        }
    },
    methods: {
        navTo(route) {
            if (this.$route.name != route.name) return this.$router.push(route);
            return;
        }
    }
}
</script>