export default [
    {
        path: 'our-story', // The URL of the post. The result will be shelfset.app/blog/example-post
        meta: {
            title: 'The ShelfSet Story',
            description: 'What is ShelfSet and why do we work on it?'
        },
        component: 'OurStory', // The Vue template file's name.
        published_at: '2021-03-02 00:00:00'
    },
    {
        path: 'how-it-works',
        meta: {
            title: 'How ShelfSet Works',
            description: 'Tips and description of how to use the app'
        },
        component: 'HowItWorks',
        published_at: '2021-03-02 00:00:00'
    },
    {
        path: 'easy-planogram-compliance',
        meta: {
            title: 'ShelfSet Makes Planogram Compliance Easy',
            description: 'ShelfSet Makes Planogram Compliance Easy'
        },
        component: 'BlogPost3',
        published_at: '2021-03-15 09:00:00'
    },
    {
        path: 'boosts-revenue-for-distributors',
        meta: {
            title: 'ShelfSet’s AI Image Recognition Planogram Solution Boosts Revenue for Distributors',
            description: 'ShelfSet offers a unique AI image recognition planogram solution to empower beverage distributors while giving them the tools they need to increase revenue.'
        },
        component: 'BlogPost4',
        published_at: '2021-03-15 10:00:00'
    },
    {
        path: 'improve-operational-efficiency',
        meta: {
            title: 'How to Improve Operational Efficiency to Maximize Shelf Space Opportunities',
            description: 'Figuring out how to improve operational efficiency can be a daunting task for any company.'
        },
        component: 'BlogPost5',
        published_at: '2021-03-15 11:00:00'
    },
]