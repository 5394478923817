<template>
    <nav class="b-pagination">
        <ul class="pagination pagination-sm justify-content-center">
            <li v-if="canGoBack" class="page-item">
                <a class="page-link prev" href="#" tabindex="-1" @click.prevent="goToPage(current_page - 1)">
                   <b-icon icon="chevron-left" size="12" solid color="primary"></b-icon>
                </a>
            </li>
            <li v-else class="page-item disabled">
                <a class="page-link prev" href="#" tabindex="-1" aria-disabled="true" @click.prevent="prevPage">
                    <b-icon icon="chevron-left" size="12" solid color="primary"></b-icon>
                </a>
            </li>
            <li class="page-item" :class="current_page == 1 ? 'active' : ''">
                <a class="page-link" @click.prevent="goToPage(1)" >{{ 1 }}</a>
            </li>
            <li v-if="is_trunced_before">
                ...
            </li>
            <template v-for="n in total">
                <li :key="n" v-if="current_page == n" class="page-item active" aria-current="page">
                    <a class="page-link "  href="#">{{ n }}<span class="sr-only">(current_page)</span></a>
                </li>
                <li :key="n" v-else>
                    <a class="page-link" @click.prevent="goToPage(n)" href="#">{{ n }}</a>
                </li>
            </template>    

            <li v-if="is_trunced_after">
                ...
            </li>
            <li class="page-item" v-if="last_page > 1" :class="current_page == last_page ? 'active' : ''">
                <a class="page-link" @click.prevent="goToPage(last_page)" >{{ last_page }}</a>
            </li>
            <li v-if="canGoForward" class="page-item">
                <a class="page-link next" href="#" @click.prevent="goToPage(current_page + 1)">
                    <b-icon icon="chevron-right" size="12" solid color="primary"></b-icon>
                </a>
            </li>
            <li v-else class="page-item disabled">
                <a class="page-link  disabled next"  href="#" aria-disabled="true" >
                    <b-icon icon="chevron-right" size="12" solid color="primary"></b-icon>
                </a>
            </li>
        </ul>
    </nav>
</template>
<script>
export default {
    props: ['current_page', 'pages'],
    data(){
        return {
            page_nums: []
        }
    },
    computed: {
        canGoForward: function() {
            return this.current_page < this.pages
        },
        canGoBack: function() {
            return this.current_page > 1 
        },
        total(){
            if(this.pages > 5){
                let begin = this.current_page ;
                let page_nums = [ begin - 1, begin , begin + 1];
                if(begin < 3) {
                    page_nums = [2, 3];
                } 
                if(begin > this.last_page - 2){
                    page_nums = [this.last_page - 2, this.last_page - 1];
                }

                return page_nums;
            }
            let page_nums = [];
            for (let i = 2; i < this.last_page ; i++) {
                page_nums.push(i)
                
            }
            return page_nums;
        },
        is_trunced_before(){
            return this.pages > 5 && this.current_page > 2;
          // return false;
        },
        is_trunced_after(){
            return this.pages > 5 && this.current_page < (this.last_page - 2);
              //return false;
        },
        last_page(){
            return this.pages
        }
    },
    methods: {
        goToPage(page) {
            this.$emit('goToPage', page)
        },
    },
    created(){
        this.page_nums = Array.from({length: this.pages}, (_, i) => i + 1);
    }
}
</script>