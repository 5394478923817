export default function(tag){
    let element = document.querySelector(`meta[name='${tag.name}']`);
    if(!element){
        element = document.createElement('meta');
        element.setAttribute('name', tag.name);
        element.content = tag.content;
        document.getElementsByTagName('head')[0].appendChild(element);
    } else {
        element.content = tag.content;
    }   
}