import posts from "../posts";
import _ from "lodash";

let routes = [
    {
        path: '/',
        name: 'home',
        component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
        meta: {
            title: "ShelfSet - Smarter Shelf Space Analysis [Retail Planogram App]",
            metaTags: [
                {
                    name: 'description',
                    content: 'Automate share of shelf tracking & POS display reporting with ShelfSet shelf space app. AI powered Retail Planogram Application: instant reporting & efficiency!'
                }
            ]
        },
        children: [
            {
                path: '/sign-up',
                name: 'sign-up',
                component: () => import(/* webpackChunkName: "sign-up" */ '../views/SignUp.vue'),
                meta: {
                    title: "ShelfSet - Sign Up for Shelf Space App Administrator Privileges",
                    metaTags: [
                        {
                            name: "description",
                            content: "Register your company for 30 days Free access to ShelfSet shelf space app & start optimizing your operational efficiency today! AI image recognition & Reporting"
                        }
                    ]
                }
            },
        ]
    },
    {
        path: '/privacy',
        name: 'privacy',
        component: () => import(/* webpackChunkName: "privacy" */ '../views/Privacy.vue'),
        meta: {
            title: "Privacy — ShelfSet",
            metaTags: [
                {
                    name: "description",
                    content: "Privacy Policy - ShelfSet Respects and Protects Your information during its collection, use and disclosure while you use our Services."
                }
            ]
        }
    },
    {
        path: '/about-us',
        name: 'about-us',
        component: () => import(/* webpackChunkName: "about-us" */ '../views/AboutUs.vue'),
        meta: {
            title: "ShelfSet - Retail Planogram Application for Beverage Brands",
            metaTags: [
                {
                    name: "description",
                    content: "Retail planogram application enables smarter & easier shelf space analysis through AI and automation. Based on over 50 years of industry knowledge & experience"
                }
            ]
        }
    },
    {
        path: '/blog',
        name: 'blog',
        component: () => import(/* webpackChunkName: "blog" */ '../views/Blog.vue'),
        meta: {
            title: "ShelfSet - Industry Insights on Shelf Space & Retail Planogram",
            metaTags: [
                {
                    name: "description",
                    content: "Read our latest blog posts to find out how to improve your operational efficiency and use share of shelf planogram application in beverage retail industry."
                }
            ]
        }
    },
    {
      path: '/app/*',
      name: 'getapp',
      component: () => import(/*webpackChunkName: "getapp" */ '../views/GetApp.vue'),
      meta: {
          title: "ShelfSet - Get the ShelfSet mobile app",
          metaTags: [
                  {
                      name: "description",
                      content: "Get the ShelfSet mobile app."
                  }
              ]
      }  
    },
    {
        path: '*',
        name: 'not_found',
        component: () => import(/* webpackChunkName: "not-found" */ '../views/NotFound.vue'),
        meta: {
            title: "Page not found — ShelfSet",
            metaTags: [
                {

                }
            ]
        }
    },
]

posts.forEach(config => {
    routes.push({
        path: `/blog/${config.path}`,
        name: `${config.path}`,
        component: () => import(`../posts/${config.component}`),
        meta: {
            title: `${_.get(config, 'meta.title', 'ShelfSet')} — Blog — ShelfSet`,
            metaTags: [
                {
                    name: 'description',
                    content: `${_.get(config, 'meta.description', '')}`
                }
            ]
        },
    })
});

export default routes;