import Vue from 'vue'
import VueRouter from 'vue-router'
import _ from 'lodash'
import renderMetaTag from '../utils/renderMetaTag';
import routes from "@/router/routes";

Vue.use(VueRouter)


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: () => ({ x: 0, y: 0 }),
})

router.beforeEach(function(to, from, next) {
  const title = _.get(to, 'meta.title', null);
  const metaTags = _.get(to, 'meta.metaTags', null);

  if(title){
    document.title = title;
  }

  if(metaTags){
    metaTags.forEach(tag => renderMetaTag(tag));
  }

  next();
});

export default router;
