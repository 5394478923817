<template>
    <div class="form-group w-100 b-input d-flex flex-column" :class="this.underline ? 'underline' : '' ">
        <label v-if="is_label" for="input">{{title}}</label> 
        <div class="position-relative">
            <input 
                @input="handleInput" 
                class="form-control"
                :value="value"
                :class="class_obj" 
                :maxlength="max_length"
                :placeholder="placeholder" 
                :name="name"
         
                :type="!is_password ? type : password_type">
            <div 
                class="b-input__append b-input__append-inner b-input__append-action" 
                v-if="is_password && !hide_password_toggler"
                @click="togglePasswordVisible">
                <b-icon color="primary">{{ password_icon }}</b-icon>
            </div>
        </div>
        <small id="hint" class="form-text text-muted" v-if="is_hint">{{hint}}</small>
        <div v-if="invalid" class="invalid-feedback">
         {{ invalid_message }}
        </div>

    </div>
</template>
<script>

export default {
    name: 'Input',
    props: {
        title: {
            type: String,
            default: ''
        },
        hint: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: ''
        },
        value: {
            type: [String, Number, Boolean],
            default: null
        },
        type: {
            type: String,
            default: 'text'
        },
        invalid: {
            type: Boolean,
            default: false
        },
        invalid_message: {
            type: String,
            default: ''
        },
        small: {
            type: Boolean,
            default: false
        },
        underline: {
            type: Boolean,
            default: false
        },
        formatter: {
            type: Function,
            default: null
        },
        max_length: {
            type: Number,
            default: 524288
        },
        name: {
            type: String,
            default: ''
        },
        hide_password_toggler: {
            type: Boolean,
            default: false
        },
        mask: {
            type: Object,
            default: null
        }
    },
    data(){
        return {
            is_password_visible: false
        }
    },
    computed: {
        is_hint(){
            return this.hint.trim().length > 0;
        },
        class_obj(){
            return {
                'is-invalid': this.invalid,
                'small': !!this.small
            }
        },
        is_label(){
            return !!this.title
        },
        is_password(){
            return this.type == 'password';
        },
        password_type(){
            return this.is_password_visible ? 'text' : 'password' 
        },
        password_icon(){
            return this.is_password_visible ? 'visibility_off' : 'visibility' 
        }
    },
    methods: {
        handleInput(e) {
            if(!this.mask){
                let val = e.target.value;
                if(this.formatter){
                    val = this.formatter(val);
                }
                this.$emit('input', val);
            }

        
        },
   

        togglePasswordVisible(){
            this.is_password_visible = !this.is_password_visible
        }
    },

    created(){
            if(!!this.value && !!this.formatter){
                let val = this.value;

                val = this.formatter(val);
                this.$emit('input', val);
            }
    }
}
</script>
