import user from './forms/user'

const state = () => ({
    
})


const actions = {

}


const mutations = {

}

const modules = {
    instance: user,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    modules
}