import axios from 'axios';

const baseURL = process.env.VUE_APP_BILLING_SERVICE;

const client = axios.create({
    baseURL: baseURL,
});

export default {
    plan: {
        index(params){
            return client.get('/plan', {params: params})
        }
    }
}
