import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import moment from 'moment';
import VueScrollTo from 'vue-scrollto';
import VueMask from 'v-mask'
import './scss/app.scss'
import './scss/main.scss'
// import 'aos/dist/aos.css';

import lazy from "./directives/lazy-load";
Vue.directive('lazy', lazy);

import {BButton, BIcon, BInput, BCheckbox, BModal, BTextArea, BPagination} from './components/elements';
import ClickOutsideDirective from './directives/click-outside';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPaperPlane, faPlane, faRocket, faTimes, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons'
library.add(
    faPaperPlane, faPlane, faRocket, faTimes, faArrowRight,
    faFacebookF, faTwitter, faInstagram
);

Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.config.productionTip = false
Vue.prototype.$moment = moment;
Vue.directive('click-outside', ClickOutsideDirective);

Vue.use(VueScrollTo, {
  container: "body",
  duration: 1500,
  easing: "ease",
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
})
Vue.use(VueMask);

Vue.component('b-button', BButton);
Vue.component('b-icon', BIcon);
Vue.component('b-input', BInput);
Vue.component('b-checkbox', BCheckbox);
Vue.component('b-modal', BModal);
Vue.component('b-textarea', BTextArea);
Vue.component('b-pagination', BPagination);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
