import axios from 'axios';

const baseURL = process.env.VUE_APP_ZENDESK_URL;

const client = axios.create({
    baseURL: baseURL,
});


export default {
    request: {
        create(form){
            return client.post('/requests.json', form)
        }
    }
}
