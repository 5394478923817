<template>
  <div id="app">
    <navbar :items="nav"></navbar>
    <router-view/>
    <b-footer></b-footer>
  </div>
</template>

<script>
import Navbar from "@/components/sections/Navbar";
import Footer from "@/components/sections/Footer";
import AOS from 'aos';
import notification from './api/notifications';

const loadZendesk = (callback) => {
  let s = document.createElement('script');
  let m = document.getElementsByTagName('script')[0];
  s.async = true;
  s.type = 'text/javascript';
  s.id = 'ze-snippet';
  s.src = 'https://static.zdassets.com/ekr/snippet.js?key=249764d6-7b7c-4731-aeb1-d87e85ed1be9';
  s.addEventListener('load', () => callback());
  m.parentNode.insertBefore(s, m);
}

export default {
  components: {
    'b-footer': Footer,
    'navbar': Navbar
  },
  data() {
    return {
      nav: [
        {title: 'Features', href: '/#features', scrollto: '#features'},
        // {title: 'About', href: '/about-us', scrollto: '#about'},
        {title: 'About Us', to: {name: 'about-us'}},
        {title: 'Contact', href: '/#contact', scrollto: '#contact'},
        {title: 'Blog', to: {name: 'blog'}},
        {title: 'Sign Up', to: {name: 'sign-up'}},
      ]
    }
  },
  created() {
    AOS.init({
      duration: 600,
      once: true
    });
  }
}
</script>
