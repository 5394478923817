<template>
    <i class="icon" :class="class_obj" :style="style_obj">
    </i>
</template>
<script>
export default {
    props: {
        size: {
            type: String,
            default: '24'
        },
        right: {
            type: Boolean,
            default: false
        },
        left: {
            type: Boolean,
            default: false
        },
        color: {
            type: String,
            default: 'primary'
        },
        solid: {
            type: Boolean,
            default: false
        },
        brand: {
            type: Boolean,
            default: false
        },
        regular: {
            type: Boolean,
            default: false
        },
        icon: {
            type: String,
            default: ''
        }
    },
    computed: {
        style_obj(){
            return {
                fontSize: this.size + 'px',
                marginRight: this.left ? '5px' : 0,
                marginLeft: this.right ? '5px' : 0,
            }
        },
        class_obj(){
            let icon = this.icon;
            let style = '';
            if(this.solid) style = 'fas';
            if(this.regular) style = 'far';
            if(this.brand) style = 'fab';
            return `${style} fa-${icon} ${this.color}`;
        }
    }
}
</script>