import api from '../../api/billing';
import _ from 'lodash';

const state = () => ({
    plans: null,
    loading: false,
});

const getters = {
    grouped: state => _.groupBy(_.get(state, 'plans', []), 'interval_unit')
}

const actions = {
    fetch: function ({commit}) {
        let promise;
        promise = api.plan.index({
            enabled: true,
            exclusive: false,
        });
        commit('setLoading', true);
        return promise
            .then(response => {
                commit('plansFetched', response.data.data)
            })
            .catch(e => {
                
            })
            .finally(() => {
                commit('setLoading', false);
            })
    }
}

const mutations = {
    setLoading(state, flag)
    {
        state.loading = flag
    },
    plansFetched(state, data)
    {
        state.plans = data;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}